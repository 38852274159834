import {
  Typography,
  Grid,
  Box,
  Stack,
  Card,
  CardContent,
  Button,
  TextField,
  Fade,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { saveEntry } from "../../store/Actions/timeCapsuleEntriesActions";
import { useForm } from "react-hook-form";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { useCallback, useEffect, useState } from "react";
import FileUploader from "../FileHandlers/FileUploader";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { removeFile } from "../../store/Actions/fileActions";

export const GamificationEntryForm = ({
  isNewEntry,
  entryData,
  onCancel,
  onSubmitSuccess,
  globalEvent,
  setGlobalEvent 
}) => {
  const dispatch = useDispatch();
  const [entryId, setEntryId] = useState(entryData?.id ?? null);
  const [title, setTitle] = useState(entryData?.title ?? "");
  const [content, setContent] = useState(entryData?.text ?? "");
  const [location, setLocation] = useState(entryData?.location ?? "");
  const [locationVisible, setLocationVisible] = useState(false);
  const [uploaderVisible, setUploaderVisible] = useState(false);

  const [hasPlan, setHasPlan] = useState(false);

  const uploadedFiles = useSelector((state) => state.file.files);

  const toggleLocationVisible = () => {
    setLocationVisible(!locationVisible);
  };

  const toggleUploaderVisible = () => {
    setUploaderVisible(!uploaderVisible);
  };

  useEffect(() => {
    if (uploadedFiles.length > 0 && entryId) {
      setUploaderVisible(true);
    }
  }, [uploadedFiles]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    mode: "onBlur",
  });

  const titleValue = watch("title");
  useEffect(() => {
    setTitle(titleValue || "");
	if (isNewEntry) { 	
	if (setGlobalEvent) { if (titleValue?.length > 0) setGlobalEvent({ type: "checkPlan", data: {type:'diaryItems'} });  } 
	}
  }, [titleValue]);

  useEffect(() => {
    if (location) {
      setLocationVisible(true);
    }
  }, []);

  const getUploadedAttachmentIds = () => uploadedFiles.map((f) => f.id);

  const onSubmit = (data) => {
    dispatch(
      saveEntry(entryId, {
        diaryItem: {
          title: data.title,
          text: data.content,
          location: data.location,
        },
        attachmentIds: getUploadedAttachmentIds(),
      })
    );

    if (onSubmitSuccess) {
      onSubmitSuccess(data);
    }

    handleReset();

    const handleRemoveFile =
      ((fileId) => {
        dispatch(removeFile(fileId));
      },
      [dispatch]);
  };

  const onError = (errors, e) => {
    //console.log(errors, e);
  };

  const handleReset = () => {
    reset();
    setLocationVisible(false);
    setUploaderVisible(false);
	setHasPlan(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleOnFileUploaded = (file) => {
    //console.log("handle on file uploaded", file);
  };

  useEffect(() => {
	//if (process.env.REACT_APP_LOG_MESSAGELOG == 'on'){
	//console.log('------ Global Event ------ GamificationEntryForm');
	//console.log(globalEvent);
	//console.log('------------');
	//}
	
	if (globalEvent?.type == "responseCheckPlan") {
    
	if (globalEvent?.data.type  == "diaryItems"){
	
	  setHasPlan(true);
	
	}
	
	}

 }, [globalEvent])



  return (
    <>
      <Card
        sx={{
          borderRadius: "16px",
          px: 2,
          pt: { xs: 1, sm: 2 },
          mt: 2,
          boxShadow: "4px 4px 20px 0px #0000001A",
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {isNewEntry
                    ? t("timeCapsule.newEntry.title")
                    : t("timeCapsule.updateEntry.title")}
                </Typography>

                <Box
                  sx={{ mt: 1 }}
                  component="form"
                  onSubmit={handleSubmit(onSubmit, onError)}
                >
                  <TextField
                    label={t("timeCapsule.newEntry.titlePlaceholder")}
                    className="fullwidth-field"
                    {...register("title", { required: true })}
                    defaultValue={title}
                    error={!!errors.title}
                    sx={{
                      marginTop: "15px !important",
                    }}
                  />

                  {title.length > 0 && (hasPlan||!isNewEntry) && (
                    <>
                      <Fade in={true} timeout={300}>
                        <TextField
                          label={t(
                            "timeCapsule.newEntry.descriptionPlaceholder"
                          )}
                          className="fullwidth-textarea"
                          {...register("content", { required: true })}
                          error={!!errors.content}
                          multiline
                          rows={4}
                          defaultValue={content}
                          sx={{
                            marginTop: "15px !important",
                            ".MuiInputBase-inputMultiline": {
                              pt: 2,
                              pl: 2.5,
                            },
                          }}
                        />
                      </Fade>
                    </>
                  )}

                  {locationVisible && (
                    <Fade in={locationVisible} timeout={300}>
                      <TextField
                        label={t("timeCapsule.newEntry.locationPlaceholder")}
                        className="fullwidth-field"
                        {...register("location", { required: locationVisible })}
                        error={!!errors.location}
                        defaultValue={location}
                        sx={{
                          marginTop: "15px !important",
                        }}
                      />
                    </Fade>
                  )}

                  {uploaderVisible && (
                    <FileUploader
                      icon={CollectionsOutlinedIcon}
                      uploadText={t("fileUploader.uploadText1")}
                      fileType={"image"}
                      onFileUploaded={() => handleOnFileUploaded}
                    ></FileUploader>
                  )}

                  {title.length > 0 && (hasPlan||!isNewEntry) && (
                    <>
                      <Fade in={title.length > 0} timeout={300}>
                        <Stack
                          direction="row"
                          sx={{ width: "100%", alignItems: "center", mt: 3 }}
                        >
                          <Button
                            variant="text"
                            className="secondary-blue-text-btn"
                            sx={{
                              height: "50px",
                              fontSize: 12,
                            }}
                            onClick={handleReset}
                          >
                            {t("timeCapsule.newEntry.cancelBtn")}
                          </Button>

                          <Box sx={{ flexGrow: 1 }} />
                          <Button
                            variant="text"
                            sx={{
                              boxShadow: "0px 0px 10px 3px #0000001A",
                              borderRadius: 64,
                              bgcolor: "main.light",
                              height: "52px",
                              width: "auto !important",
                            }}
                            onClick={toggleLocationVisible}
                          >
                            <AddLocationAltOutlinedIcon />
                          </Button>

                          <Button
                            sx={{
                              bgcolor: "main.light",
                              boxShadow: "0px 0px 10px 3px #0000001A",
                              borderRadius: 64,
                              height: "52px",
                              width: "auto !important",
                              mx: 2,
                            }}
                            onClick={toggleUploaderVisible}
                          >
                            <AddPhotoAlternateOutlinedIcon />
                          </Button>

                          <Button
                            variant="contained"
                            className="secondary-blue-btn"
                            type="submit"
                            sx={{
                              bgcolor: "main.light",
                              height: "52px",
                              width: "auto !important",
                            }}
                          >
                            {t("timeCapsule.newEntry.submitBtn")}
                            <ArrowForwardIcon
                              sx={{
                                fontSize: "inherit",
                                strokeWidth: "1",
                                ml: 3,
                              }}
                            />
                          </Button>
                        </Stack>
                      </Fade>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
	
export default GamificationEntryForm;
