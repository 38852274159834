import { Box, Grid } from "@mui/material";
import { LoginTopMenuLogout } from "../Navigation/LoginNavigation/LoginTopMenuLogout";
import registrationbg from "../Assets/Registration-bg.png";
import HomeComponent from "../HomePage/HomeComponent";

import { ValidationStep1 } from "../ValidationComponents/ValidationStep1";

import React, { useEffect, useState } from "react";

import { getCurrentUser } from "../../store/Actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { checkHasToken, checkTokenIsValid } from "../DataModule/tokenFunctions";

import { AddDataStep1 } from "../AddUserData/AddDataStep1";
import addbg from "../Assets/add-user-data-bg.png";
import Dashboard from "../Dashboard/Dashboard";
import DashboardContact from "../Dashboard/Contact/DashboardContact";
import { fetchAssets } from "../../store/Actions/assetAction";
import { fetchDiaryEntries } from "../../store/Actions/diaryAction";
import { fetchPostMortemEntries } from "../../store/Actions/postMortemActions";
import { t } from "i18next";

const Landing = ({ globalEvent, setGlobalEvent, title, cStep }) => {
  const [checked, setChecked] = useState(false);
  const [verified, setVerified] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [secondLogin, setSecondLogin] = useState(false);

  const dispatch = useDispatch();
  const { user, loading, loggedIn } = useSelector((state) => state.user);

  const [hasToken, setHasToken] = useState(false);
  const [tokenIsValid, setTokenIsValid] = useState(false);

  const [mobileView, setMobileView] = useState(
    window.matchMedia("(max-width: 900px)").matches
  );

  const { hats } = useSelector((state) => state.hats);
  const isUnderCap =
    localStorage.getItem("chid") !== null &&
    localStorage.getItem("chid") !== "null";

  useEffect(() => {
    const tokenExists = checkHasToken();

    if (tokenExists) {
      setHasToken(true);
      const isValid = checkTokenIsValid();
      setTokenIsValid(isValid);

      if (isValid) {
        dispatch(getCurrentUser());
        dispatch(fetchAssets());
        dispatch(fetchDiaryEntries());
        dispatch(fetchPostMortemEntries());
      }
    } else {
      setTimeout(() => setPageLoaded(true), 500);
    }
  }, [dispatch, loggedIn]);

  useEffect(() => {
    if (loading === false && user) {
      if (user.emailVerified || user.email_verified_at) {
        setVerified(true);
      }

      if (user.loginCount > 0) {
        setSecondLogin(true);
      }

      setChecked(true);
      setTimeout(() => setPageLoaded(true), 500);
    }
  }, [loading, user]);

  return (
    pageLoaded && (
      <>
        {(hasToken === false || tokenIsValid === false) && (
          <HomeComponent
            globalEvent={globalEvent}
            setGlobalEvent={setGlobalEvent}
            title={t('home.pageTitle')}
          />
        )}

        {tokenIsValid === true && checked && !verified && loading === false && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Grid container sx={{}}>
              <Grid item xs={12} md={6}>
                <LoginTopMenuLogout />
                <div className="login-container">
                  <ValidationStep1
                    globalEvent={globalEvent}
                    setGlobalEvent={setGlobalEvent}
                    validationType="registration"
                  />
                </div>
              </Grid>

              {mobileView ? (
                ""
              ) : (
                <Grid item xs={12} md={6}>
                  <Box
                    component="img"
                    alt="background"
                    src={registrationbg}
                    sx={{
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  ></Box>
                </Grid>
              )}
            </Grid>
          </Box>
        )}

        {hasToken &&
          tokenIsValid &&
          checked &&
          verified &&
          secondLogin &&
          !user.firstName &&
          user.id &&
          loading === false && (
            <>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Grid container sx={{}}>
                  <Grid item xs={12} md={6}>
                    <div className="login-container">
                      <Box
                        sx={{
                          mt: { xs: 0, sm: 16 },
                        }}
                      >
                        <AddDataStep1
                          globalEvent={globalEvent}
                          setGlobalEvent={setGlobalEvent}
                        />
                      </Box>
                    </div>
                  </Grid>
                  {mobileView ? (
                    ""
                  ) : (
                    <Grid item xs={12} md={6}>
                      <Box
                        component="img"
                        alt="background"
                        src={addbg}
                        sx={{
                          top: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      ></Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </>
          )}

        {hasToken &&
          tokenIsValid &&
          checked &&
          verified &&
          user.id &&
          (!secondLogin || !!user.firstName) &&
          (isUnderCap === false ? (
            <Dashboard
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
              title={t('home.pageTitle')}
            />
          ) : (
            <DashboardContact
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
              title={t('home.pageTitle')}
            />
          ))}
      </>
    )
  );
};

export default Landing;
