import { Box, Typography, Paper, Stack } from '@mui/material';
import ButtonwithArrow from './ButtonWithArrow';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';

const HintDisplay = () => {
  const navigate = useNavigate();
  const { hints } = useSelector((state) => state.hints); 

  useEffect(() => {
    //console.log('hints', hints);
  }, [hints]);

  if (!hints) {
    return null;
  }

  const links = hints.links ? JSON.parse(hints.links) : [];

  return (hints && hints.title) && (
    <Paper
      elevation={3}
      sx={{
        padding: '24px',
        textAlign: 'center',
        margin: '0 auto',
        py: 2,
        px: 5,
        my: 2,
        borderRadius: '24px',
        border: '1px solid #2B36741A',
        boxShadow: 'none',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
        <img
          src={hints.image}
          alt={hints.title}
        />
      </Box>
      <Stack>
        <Typography variant="body1bold" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#1C3D7D', mb: 2 }}>
          {hints.title}
        </Typography>
        <Typography variant="body3regular" sx={{ color: '#2B3674', marginBottom: '24px' }}>
          <div dangerouslySetInnerHTML={{ __html: hints.content }} /> 
        </Typography>
      </Stack>
      {links.map((link, index) => (
        <ButtonwithArrow
          key={index}
          text={link.text}
          className="white-btn"
          fillColor='#2B3674'
          onClick={() => {
            if (link.url.startsWith('http://') || link.url.startsWith('https://') || link.url.startsWith('www.')) {
              window.open(link.url, '_blank');
            } else {
              navigate(link.url);
            }
          }}
        />
      ))}
    </Paper>
  );
};

export default HintDisplay;
