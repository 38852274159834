import { Button } from "@mui/material";
import { t } from "i18next";

const ButtonwithArrow = ({ text, className, onClick, fillColor = '#E5FE4A' }) => {
  return (
    <>
      <Button
        className={className}
        type="submit"
        variant="contained"
        onClick={onClick}
        sx={{
          lineHeight: "20.16px",
          my: 3,
          py: 2,
          px: 4,
          minWidth: { xs: "310px", md: "auto" },
          width: { xs: "310px", md: "auto" },
        }}
        endIcon={
          <svg
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
              fill={fillColor}
            />
          </svg>
        }
      >
        {text}
      </Button>
    </>
  );
};

export default ButtonwithArrow;
