import React, { useEffect, useState } from "react";
import { Typography, Grid, Stack, Box, Button } from "@mui/material";
import { HelpCard } from "../Shared/Cards/HelpCard";
import { ReferralCard } from "../Shared/Cards/ReferralCard";
import { ContactCard } from "./ContactCard";
import { EmptyCard } from "../Common/EmptyCard";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultLayout from "../Layout/DefaultLayout";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LimitReached from "../Commons/LimitReached";
import HintDisplay from "../Commons/HintDisplay";

const ContactListIndex = ({ globalEvent, setGlobalEvent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [user, setUser] = useState();
  const [contacts, setContacts] = useState([]);
  const [sums, setSums] = useState();
  const [filterType, setFilterType] = useState("");
  const [fixFilter, setFixFilter] = useState(false);
  const [recCount, setRecCount] = useState(10);
  const [onLoad, setOnLoad] = useState(true);
  const chid = localStorage.getItem("chid");

  useEffect(() => {
	setOnLoad(true);
    setGlobalEvent({ type: "doGetUser", data: {} });
  }, [setGlobalEvent]);

  useEffect(() => {
    const state = location.state || {};
    setFilterType(state.filterType || "");
    setFixFilter(!!state.filterType);
  }, [location.state]);

  useEffect(() => {
    setRecCount(filterType === "" ? 10 : 9999);
  }, [filterType]);

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG === "on") {
      //console.log("------ Global Event ------ ContactList");
      //console.log(globalEvent);
      //console.log("------------");
    }

    if (globalEvent?.type === "responseGetUser") {
      setUser(globalEvent.data.user);
    }

    if (globalEvent?.type === "responseGetContacts") {
      const { contacts } = globalEvent.data;
      setContacts(contacts);
	  setOnLoad(false);
    }

    if (globalEvent?.type === "responseGetSums") {
      setSums(globalEvent.data.sums);
    }
	
   if (globalEvent?.type == "responseCheckPlan") {
      if (globalEvent?.data.link == '/add-new-contact') {

        navigate(globalEvent?.data.link, {
          state: {
            editmode: true,
            data: {}
          },
        });
      }
    }
	
	
  }, [globalEvent]);

  const beneficiaryPersonList = contacts.filter(contact =>
    ["instant", "timed", "onDeath"].includes(contact.accessTypeAssets)||
	["instant", "timed", "onDeath"].includes(contact.accessTypeDiaryItems)||
	["instant", "timed", "onDeath"].includes(contact.accessTypePostMortemRequests)
  );

  const trustedPersonList = contacts.filter(contact =>
    !["instant", "timed", "onDeath"].includes(contact.accessTypeAssets) &&
	!["instant", "timed", "onDeath"].includes(contact.accessTypeDiaryItems) &&
	!["instant", "timed", "onDeath"].includes(contact.accessTypePostMortemRequests)
  );

  const renderContactList = (list, titleKey, fType) => (
    <Box sx={{ mb: 4 }}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          variant="body3"
          sx={{ mb: 6, pl: { xs: 2, sm: 0 }, fontWeight: 700 }}
        >
		{fixFilter === true && (
            <>
              {fType} ({list.length})
            </>
        )}		
		{fixFilter === false && (
            <>
              {ReactHtmlParser(t(titleKey))} ({list.length})
            </>
          )}
        </Typography>
        {fixFilter === false && (
          <Button
            onClick={() => setFilterType(filterType === "" ? fType : "")}
            variant="text"
            sx={{ height: "52px", width:"auto !important" }}
            endIcon={
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6116 6.74983H1.25C1.03718 6.74983 0.858983 6.67804 0.7154 6.53446C0.5718 6.39087 0.5 6.21267 0.5 5.99986C0.5 5.78704 0.5718 5.60884 0.7154 5.46526C0.858983 5.32167 1.03718 5.24988 1.25 5.24988H12.6116L9.3192 1.95753C9.17048 1.80882 9.09709 1.63478 9.09902 1.43543C9.10094 1.23608 9.17433 1.05884 9.3192 0.903707C9.47433 0.748591 9.65253 0.668466 9.8538 0.663333C10.0551 0.658199 10.2333 0.733199 10.3884 0.888333L14.8672 5.36718C14.9608 5.46077 15.0269 5.55948 15.0653 5.66333C15.1038 5.76717 15.123 5.87934 15.123 5.99986C15.123 6.12037 15.1038 6.23255 15.0653 6.33638C15.0269 6.44023 14.9608 6.53895 14.8672 6.63253L10.3884 11.1114C10.2397 11.2601 10.0631 11.3335 9.85863 11.3316C9.65414 11.3296 9.47433 11.2511 9.3192 11.096C9.17433 10.9409 9.09933 10.7652 9.0942 10.5691C9.08908 10.3729 9.16408 10.1973 9.3192 10.0422L12.6116 6.74983Z"
                  fill="#62C275"
                />
              </svg>
            }
          >
            {ReactHtmlParser(t("common.showAll"))}
          </Button>
        )}
      </Stack>
      <Stack spacing={2}>
        {list.slice(0, recCount).map(contact => (
          <ContactCard
            key={contact.id}
            icon=""
            title={`${contact.lastName} ${contact.firstName}`}
            info={t(`contact.contactTypes.${contact.relationType}`)}
            contactStatus={contact.status}
            contactStatusText={t(`contact.contactStatus.${contact.status}`)}
            linkTo={() => navigate("/add-new-contact", { state: { data: contact } })}
          />
        ))}
      </Stack>
     
    </Box>
  );

  return (
    <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
      <Box className="my-container">
        <Grid
          sx={{
            mt: { xs: 0, sm: 8 },
            mb: 20,
            textAlign: "left",
          }}
          container
        >
          <Grid item xs={12} sx={{ pr: { md: 3 } }}>
            <Stack
              direction={{ sx: "column", md: "row" }}
              justifyContent={"space-between"}
              sx={{ mb: 6 }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: { xs: 3, md: 0 },
                  pl: { xs: 2, sm: 0 },
                  fontWeight: 700,
                }}
              >
                {fixFilter === false &&
                  trustedPersonList.length * 1 < 1 &&
                  beneficiaryPersonList.length * 1 < 1 && (
                    <>{ReactHtmlParser(t("contact.contactList"))}</>
                )}

                {fixFilter === false &&
                  (trustedPersonList.length * 1 > 0 ||
                    beneficiaryPersonList.length * 1 > 0) && (
                    <>
                      {ReactHtmlParser(t("contact.contactList"))}{" "}
                      {"(" +
                        (trustedPersonList.length * 1 +
                          beneficiaryPersonList.length * 1) +
                        ")"}
                    </>
                )}

                {fixFilter === true && filterType === "beneficiary" && (
                  <>
                    {ReactHtmlParser(t("contact.beneficiaryPersonList"))} ({beneficiaryPersonList.length}){" "}
                  </>
                )}
                {fixFilter === true && filterType === "trusted" && (
                  <> {ReactHtmlParser(t("contact.trustedPersonList"))} ({trustedPersonList.length}) </>
                )}
              </Typography>
              {!chid || chid === "null" ? (
              <Button

                onClick={() => {
								setGlobalEvent({
                                    type: "checkPlan",
                                    data: {
                                      type: "contact",
                                      link: "/add-new-contact",
                                    },
                                  });

				}}
				className="small-btn green-btn"
                variant="contained"
                sx={{  height: "52px", width:"auto !important" }}
                endIcon={
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
                      fill="white"
                    />
                  </svg>
                }
              >
                {ReactHtmlParser(t("contact.addNewContact"))}
              </Button>
                  ) : (
                    <></>
                  )}
            </Stack>
          </Grid>

          <LimitReached limitType="contacts" count={contacts?.length} />

		  <Grid item xs={12} md={7} sx={{ pr: { md: 3 } }}>
		  {(!onLoad)&&(<>
            {(filterType === "" || filterType === "beneficiary") &&
              beneficiaryPersonList.length > 0 &&
              renderContactList(beneficiaryPersonList, "contact.beneficiaryPersonList","beneficiary")}
            
            {(filterType === "" || filterType === "trusted") &&
              trustedPersonList.length > 0 &&
              renderContactList(trustedPersonList, "contact.trustedPersonList","trusted")}
            
            {beneficiaryPersonList.length * 1 < 1 &&
              trustedPersonList.length * 1 < 1 && (
                <EmptyCard
                  icon={
                    <PermIdentityOutlinedIcon
                      sx={{ fontSize: 50, fill: "#2B3674" }}
                    />
                  }
                  buttonClass="small-btn green-btn"
                  title={t("contact.noContactTitle")}
                  description={t("contact.noContactDescription")}
                  buttonText={t("contact.noContactButtonText")}
	                  buttonLink={() => navigate("/add-new-contact", { state: { editmode: true, data: {} } })}
                />
            )}
          </>)}

          
          <HintDisplay />
		  </Grid>
          
		  <Grid item xs={12} md={5} sx={{ px: { md: 3 } }}>
            <Stack direction={"column"} spacing={2}>
              <HelpCard />
              <ReferralCard />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </DefaultLayout>
  );
};

export default ContactListIndex;
